import { notificationActionCreators } from '../../components/Notification';
import { progressActionCreators } from '../../components/Progress';

import {
  IOrganisation,
  IOrganisationMember,
  //IOrganisationExternalMember,
  IOrganisationMemberResults,
  IOrganisationLicenceResults,
  ILicence,
  IOrganisationMemberSearchParams,
  IOrganisationMessage,
  IGovTEAMSResponse,
  IOrganisationExternalMemberResults,
  IOrganisationDefaultCommunities
} from './Organisation.types';
import { actions } from './Organisation.actions';
import { constants } from './Organisation.constants';
import * as globalConstants from '../../common/Constants';
import { actions as externalMemberReviewActions } from '../../screens/ExternalMemberReview/ExternalMemberReview.actions';
import { actions as udfValueMemberActions } from '../../screens/OrganisationUdfValue/OrganisationUdfValue.actions';
import { replace } from 'react-router-redux';
import { getAuthFromState } from '../../auth';
import {
  httpServiceSecureDelete,
  httpServiceSecureGet,
  httpServiceSecureGetRaw,
  httpServiceSecurePost, httpServiceSecurePut
} from '../../common/Http';
import {saveDashboardInteractionEvent} from "../../common/EventTracking";
import { updateUIWithErrorMessage } from '../../common/HelperFunctions';

const FileSaver = require('file-saver');

export const organisationActionCreators = {
  requestFetchOrganisation: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_FETCH)
    );

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const organisationResponse: IGovTEAMSResponse<IOrganisation> = await httpServiceSecureGet(`/organisations/${externalguid}`, gtToken);

      dispatch({
        type: actions.ORG_FETCH_RESPONSE,
        organisation: organisationResponse.result
            ? organisationResponse.result : undefined
      });

    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_RESPONSE,
        organisationError: error.result || error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestFetchOrganisationAdmins: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }
    dispatch({ type: actions.ORG_FETCH_ADMINS_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const organisationResponse: IGovTEAMSResponse<IOrganisationMember[]> = await httpServiceSecureGet(`/organisations/${externalguid}/Admins`, gtToken);

      dispatch({
        type: actions.ORG_FETCH_ADMINS_RESPONSE,
        admins: organisationResponse?.result || undefined
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_ADMINS_RESPONSE,
        error
      });
    }
  },

  requestFetchOrganisationMessage: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_MESSAGE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_FETCH)
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json: IGovTEAMSResponse<any> = await httpServiceSecureGet(`/organisations/${externalguid}/Messaging`, gtToken);
      const message = json.result;

      dispatch({
        type: actions.ORG_FETCH_MESSAGE_RESPONSE,
        message: message.innerResult
            ? (message.innerResult as IOrganisationMessage)
            : undefined
      });

    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_MESSAGE_RESPONSE,
        message: null,
        organisationError: error.result || error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestSaveOrganisation: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_SAVE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_SAVE)
    );

    try {
      const OrganisationEditForm = getState().form.OrganisationEditForm.values;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePost(`/organisations/${externalguid}/Messaging`, gtToken, OrganisationEditForm);

      if (json.code === 0) {
        dispatch(
          notificationActionCreators.startShowNotification(
            json ? json.message : ''
          )
        );
        dispatch({
          type: actions.ORG_SAVE_RESPONSE
        });
      } else {
        dispatch(
          notificationActionCreators.startShowNotification(
            json ? json.message : ''
          )
        );
        dispatch({
          type: actions.ORG_FETCH_RESPONSE,
          organisationError: json.innerResult.errors
        });
      }
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_SAVE_RESPONSE,
        organisationError: error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  resetEditForm: () => (dispatch, getState) => {
    dispatch({
      type: actions.ORG_EDIT_FORM_RESET
    });
  },

  requestFetchOrganisationMembers: (
    externalguid: string,
    params: IOrganisationMemberSearchParams = {
      searchWord: '',
      domains: [],
      statuses: [],
      memberTypes: [],
      pageNumber: 1,
      rowsPerPage: 20
    }
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_MEMBERS_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_MEMBERS_FETCH
      )
    );

    try {
      const domains =
        params.domains && params.domains.length > 0
          ? `&filterDomains=${params.domains.join()}`
          : '';
      const statuses =
        params.statuses && params.statuses.length > 0
          ? `&filterStatuses=${params.statuses.join()}`
          : '';
      const memberTypes =
        params.memberTypes && params.memberTypes.length > 0
          ? `&filterMemberTypes=${params.memberTypes.join()}`
          : '';
      const url = `/organisations/${externalguid}/MembersWithUdf?pageNumber=${
          params.pageNumber
        }&rowsPerPage=${params.rowsPerPage}${
            params.searchWord && '&searchWord=' + params.searchWord
        }${domains}${statuses}${memberTypes}`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersResult: IGovTEAMSResponse<IOrganisationMemberResults> = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_FETCH_MEMBERS_RESPONSE,
        membersResult: membersResult.result ? membersResult.result : undefined
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_MEMBERS_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestFetchAllOrganisations: () => async (dispatch, getState) => {
    if (getState().organisation.isLoadingAll) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_ALL_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const organisationResponse: IGovTEAMSResponse<IOrganisation[]> = await httpServiceSecureGet('/organisations', gtToken);
      const organisations: IOrganisation[] = organisationResponse.result;

      dispatch({
        type: actions.ORG_FETCH_ALL_RESPONSE,
        organisations: organisations || undefined
      });
    } catch (error) {
      dispatch({ type: actions.ORG_FETCH_ALL_RESPONSE, error });
    }
  },

  requestFetchOrganisationDomains: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_DOMAINS_FETCH_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const organisationResponse: IGovTEAMSResponse<string[]> = await httpServiceSecureGet(`/organisations/${externalguid}/Domains`, gtToken);

      dispatch({
        type: actions.ORG_DOMAINS_FETCH_RESPONSE,
        domains: organisationResponse?.result || undefined
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_DOMAINS_FETCH_RESPONSE,
        error
      });
    }
  },

  requestFetchOrganisationLicence: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }
    dispatch({ type: actions.ORG_LICENCE_FETCH_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const organisationResponse: IGovTEAMSResponse<ILicence> = await httpServiceSecureGet(`/organisations/${externalguid}/Licence`, gtToken);

      dispatch({
        type: actions.ORG_LICENCE_FETCH_RESPONSE,
        licence: organisationResponse?.result || undefined,
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_LICENCE_FETCH_RESPONSE,
        error
      });
    }
  },

  allocateLicences: (
    externalguid: string,
    members: IOrganisationMember[]
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_ALLOCATE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_ALLOCATION
      )
    );

    try {
      const memberGuids = members.map((member: IOrganisationMember) => {
        return member.azureId;
      });

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersActionResult: any = await httpServiceSecurePost(`/organisations/${externalguid}/Members/AssignLicence`, gtToken, { Members: memberGuids });

      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_ALLOCATE_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result));
      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_ALLOCATE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  deactivateLicences: (
    externalguid: string,
    members: IOrganisationMember[]
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_DEACTIVATE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_DEACTIVATION
      )
    );

    try {
      const memberGuids = members.map((member: IOrganisationMember) => {
        return member.azureId;
      });

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersActionResult: any = await httpServiceSecurePost(`/organisations/${externalguid}/Members/DeactivateMembers`, gtToken, { Members: memberGuids });

      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_DEACTIVATE_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result));
      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_DEACTIVATE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  moveToWaitlist: (
    externalguid: string,
    members: IOrganisationMember[]
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_REMOVE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_REMOVAL
      )
    );

    try {
      const memberGuids = members.map((member: IOrganisationMember) => {
        return member.azureId;
      });

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersActionResult: any = await httpServiceSecurePost(`/organisations/${externalguid}/Members/RemoveLicence`, gtToken, { Members: memberGuids });

      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_REMOVE_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result));
      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_REMOVE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  clearActionResult : () => async (dispatch, getState) => {
    dispatch({ type: actions.ORG_CLEAR_ACTION_RESULT });
  },

  exportFilteredOrganisationMemberCsv: (
    externalguid: string,
    params: IOrganisationMemberSearchParams = {
      searchWord: '',
      domains: [],
      statuses: [],
      pageNumber: 1,
      rowsPerPage: 20
    }
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_EXPORTCSV_REQUEST
    });

    try {
      const domains = params.domains
        ? `&filterDomains=${params.domains.join()}`
        : '';
      const statuses = params.statuses
        ? `&filterStatuses=${params.statuses.join()}`
        : '';
      const url = `/organisations/${externalguid}/Members/filtered_members_withudf.csv?pageNumber=${
          params.pageNumber
        }&rowsPerPage=${params.rowsPerPage}${
          params.searchWord && '&searchWord=' + params.searchWord
        }${domains}${statuses}`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureGetRaw(url, gtToken, { Accept: 'text/csv' })
          .then((res) => res.blob())
          .then((blob) => FileSaver.saveAs(blob, `${externalguid}__filteredMembers.csv`));

      dispatch({
        type: actions.ORG_MEMBERS_EXPORTCSV_RESPONSE
      });
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      dispatch(progressActionCreators.endShowProgress());
      dispatch({
        type: actions.ORG_MEMBERS_EXPORTCSV_RESPONSE,
        error
      });
    }
  },

  exportOrganisationMemberCsv: (externalguid: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_EXPORTCSV_REQUEST
    });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureGetRaw(`/organisations/${externalguid}/Members/members_withudf.csv`, gtToken, { Accept: 'text/csv' })
          .then((res) => res.blob())
          .then((blob) => FileSaver.saveAs(blob, `${externalguid}__members.csv`));

      dispatch({
        type: actions.ORG_MEMBERS_EXPORTCSV_RESPONSE
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_MEMBERS_EXPORTCSV_RESPONSE,
        error
      });
    }
  },

  reserveLicences: (
    externalguid: string,
    isDelete: boolean = false,
    emails: string[] | null = null
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_RESERVE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_RESERVE
      )
    );

    try {
      const MembersLicencesReserveForm = !emails
        ? getState().form.MembersLicencesReserveForm.values.emails
        : emails.join('\n');

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const httpFunc = isDelete ? httpServiceSecureDelete:httpServiceSecurePost;
      const membersActionResult: any = await httpFunc(`/organisations/${externalguid}/Members/Reserved`, gtToken, MembersLicencesReserveForm);

      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_RESERVE_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_RESERVE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  /**
   * search reserved
   */
  requestFetchReservedMembers: (
    externalguid: string,
    params: IOrganisationMemberSearchParams = {
      searchWord: '',
      pageNumber: 1
    }
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_FETCH_MEMBERS_RESERVED_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_RESERVED_FETCH
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const url = `/organisations/${externalguid}/Members/Reserved?pageNo=${params.pageNumber}&rowsPerPage=${params.rowsPerPage}${params.searchWord && '&searchWord=' + params.searchWord}`;
      const membersReserved: IGovTEAMSResponse<IOrganisationLicenceResults> = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_FETCH_MEMBERS_RESERVED_RESPONSE,
        membersReserved: membersReserved ? membersReserved.result : ''
      });

    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_MEMBERS_RESERVED_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  blockLicences: (
    externalguid: string,
    isDelete: boolean = false,
    emails: string[] | null = null
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_BLOCK_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_BLOCK
      )
    );

    try {
      const MembersLicencesBlockForm = !emails
        ? getState().form.MembersLicencesBlockForm.values.emails
        : emails.join('\n');

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const httpFunc = isDelete ? httpServiceSecureDelete:httpServiceSecurePost;
      const membersActionResult: any = await httpFunc(`/organisations/${externalguid}/Members/Blocked`, gtToken, MembersLicencesBlockForm);

      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_BLOCK_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_MEMBERS_LICENCE_BLOCK_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestFetchBlockedMembers: (
    externalguid: string,
    params: IOrganisationMemberSearchParams = {
      searchWord: '',
      pageNumber: 1,
      rowsPerPage: 20
    }
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_FETCH_MEMBERS_BLOCKED_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_LICENCE_BLOCKED_FETCH
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const url = `/organisations/${externalguid}/Members/Blocked?pageNo=${params.pageNumber}&rowsPerPage=${params.rowsPerPage}${params.searchWord && '&searchWord=' + params.searchWord}`;
      const membersBlocked: IGovTEAMSResponse<IOrganisationLicenceResults> = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_FETCH_MEMBERS_BLOCKED_RESPONSE,
        membersBlocked: membersBlocked ? membersBlocked.result : ''
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_MEMBERS_BLOCKED_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  resetLicencesForm: () => (dispatch, getState) => {
    dispatch({
      type: actions.ORG_MEMBERS_LICENCE_FORM_RESET
    });
  },

  requestFetchExternalOrganisationMembers: (
    externalguid: string,
    params: IOrganisationMemberSearchParams = {
      searchWord: '',
      domains: [],
      statuses: [],
      memberTypes: [],
      pageNumber: 1,
      rowsPerPage: 20
    }
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_EXTERNAL_MEMBERS_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_EXT_MEMBERS_FETCH
      )
    );

    try {
      const domains =
        params.domains && params.domains.length > 0
          ? `&filterDomains=${params.domains.join()}`
          : '';
      const statuses =
        params.statuses && params.statuses.length > 0
          ? `&filterStatuses=${params.statuses.join()}`
          : '';
      const memberTypes =
        params.memberTypes && params.memberTypes.length > 0
          ? `&filterMemberTypes=${params.memberTypes.join()}`
          : '';
      const url = `/organisations/${externalguid}/Members/External?pageNumber=${
            params.pageNumber
        }&rowsPerPage=${params.rowsPerPage}${
            params.searchWord && '&searchWord=' + params.searchWord
        }${domains}${statuses}${memberTypes}`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersResult: IGovTEAMSResponse<IOrganisationExternalMemberResults> = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_FETCH_EXTERNAL_MEMBERS_RESPONSE,
        membersResult: membersResult.result ? membersResult.result : undefined
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_EXTERNAL_MEMBERS_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestFetchIPRange: (
    externalguid: string,
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_FETCH_IP_RANGE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_IP_RANGE_FETCH
      )
    );

    try {
      const url = `/organisations/${externalguid}/IpAddress`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const ipRanges = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_FETCH_IP_RANGE_RESPONSE,
        ipRangeResult: ipRanges.result ? ipRanges.result : undefined,
        ipRangeMessage: ipRanges.message ? ipRanges.message : undefined,
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_FETCH_IP_RANGE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  startExternalMemberReview: (organisationId: string, memberAzureId: string) => (
    dispatch    
  ) => {
    dispatch({ type: externalMemberReviewActions.ORG_EXTERNAL_MEMBER_REVIEW_START, organisationId, memberAzureId });
    dispatch(
      replace(`${globalConstants.SCREEN_ORGANISATION}/${organisationId}/ExternalMembers/${memberAzureId}/Review`)
    );
  },

  reviewExternalMember: (
    externalguid: string,
    memberAzureId: string
  ) => async (dispatch, getState) => {
    // if (getState().organisation.isLoading) {
    //   return;
    // }

    dispatch({
      type: actions.ORG_EXTERNAL_MEMBER_REVIEW_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_EXT_MEMBER_REVIEW_FETCH
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersActionResult: any = await httpServiceSecureGet(`/organisations/${externalguid}/Members/External/${memberAzureId}`, gtToken);

      dispatch({
        type: actions.ORG_EXTERNAL_MEMBER_REVIEW_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_EXTERNAL_MEMBER_REVIEW_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  removeExternalMember: (
    externalguid: string,
    memberAzureId: string
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({
      type: actions.ORG_EXTERNAL_MEMBER_REMOVE_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_ORG_EXT_MEMBER_REMOVE_FETCH
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const membersActionResult: any = await httpServiceSecureGet(`/organisations/${externalguid}/Members/External/${memberAzureId}/Remove`, gtToken);

      dispatch({
        type: actions.ORG_EXTERNAL_MEMBER_REMOVE_RESPONSE,
        membersActionResult
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_EXTERNAL_MEMBER_REMOVE_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestFetchOrganisationDefaultCommunities: (externalguid: string) => async (
      dispatch,
      getState
  ) => {
      if (getState().organisation.isLoading) {
          return;
      }
      dispatch({ type: actions.ORG_FETCH_ORG_DEFAULT_COMMUNITY_REQUEST });

      try {
          const { gtToken } = await getAuthFromState(getState, dispatch);
          const orgDefaultCommunityResponse: IGovTEAMSResponse<IOrganisationDefaultCommunities[]> = await httpServiceSecureGet(`/organisations/${externalguid}/GetOrgDefaultCommunitiesList`, gtToken);

          dispatch({
              type: actions.ORG_FETCH_ORG_DEFAULT_COMMUNITY_RESPONSE,
              orgDefaultCommunities: orgDefaultCommunityResponse?.result || undefined
          });
      } catch (error) {
          dispatch({
              type: actions.ORG_FETCH_ORG_DEFAULT_COMMUNITY_RESPONSE,
              error
          });
      }
  },

  requestFetchUdfMaster: (
    externalguid: string,
  ) => async (dispatch, getState) => {
    dispatch({ type: actions.ORG_UDF_MASTER_FETCH_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_MASTER_FETCH)
    );
    console.log('Fetching UDF Master');

    try {
      const url = `/organisations/${externalguid}/masterUdfValues/`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const masterValues = await httpServiceSecureGet(url, gtToken);

      dispatch({
        type: actions.ORG_UDF_MASTER_FETCH_RESPONSE,
        udfMasterList: masterValues.result ? masterValues.result : undefined,
      });
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_MASTER_FETCH_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_master_field_list', externalguid);
    }
  },

  requestAddUdfMaster: (externalguid: string, formData: any) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_MASTER_SAVE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_MASTER_SAVE));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePost(`/organisations/${externalguid}/masterUdfValues`, gtToken, {showInMemberList: false, ...formData, fieldType: 'string'});

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_MASTER_SAVE_RESPONSE,
        json
      });

    } catch (error) {
      updateUIWithErrorMessage(
        error,
        dispatch,
        notificationActionCreators.startShowNotification,
        actions.ORG_UDF_MASTER_SAVE_RESPONSE
      );
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_master_field_add', externalguid);
    }
  },

  requestEditUdfMaster: (externalguid: string, masterId: number, formData: any) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_MASTER_SAVE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_MASTER_SAVE));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePut(`/organisations/${externalguid}/masterUdfValues/${masterId}`, gtToken, {
        showInMemberList: !!formData.showInMemberList,
        fieldName: formData.fieldName,
        fieldType: 'string',
      });

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_MASTER_SAVE_RESPONSE,
        message: json?.message,
      });

    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_MASTER_SAVE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_master_field_edit', externalguid);
    }
  },

  requestDeleteUdfMaster: (externalguid: string, masterId: number) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_MASTER_DELETE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_MASTER_DELETE));

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecureDelete(`/organisations/${externalguid}/masterUdfValues/${masterId}`, gtToken);

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_MASTER_DELETE_RESPONSE,
        message: json?.message,
      });

      // if (json?.innerResult?.errors) {
      //   dispatch({
      //     type: actions.ORG_UDF_MASTER_DELETE_RESPONSE,
      //     error: json.innerResult.errors
      //   });
      // }
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_MASTER_DELETE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_master_field_delete', externalguid);
    }
  },

  requestBulkUdfValues: (
    externalguid: string,
    fieldId: number,
    fieldValue: string,
    memberAzureIds: string[],
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_VALUE_BULK_SAVE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_VALUE_BULK_SAVE)
    );

    try {
      const url = `/organisations/${externalguid}/Members/udfValues/`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePut(url, gtToken, {
        fieldId: fieldId,
        fieldValue: fieldValue,
        memberAzureIds: memberAzureIds,
      });

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_VALUE_BULK_SAVE_RESPONSE,
        message: json?.message,
      });
    } catch (error) {
      let errMessage = error?.message;
      if ( !errMessage )
        errMessage = error.result ? error.result:error.statusText;
      dispatch(notificationActionCreators.startShowNotification(errMessage));
      dispatch({
        type: actions.ORG_UDF_VALUE_BULK_SAVE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_field_value_bulk', externalguid);
    }
  },

  startMemberUdfValue: (organisationId: string, memberAzureId: string) => (
    dispatch
  ) => {
    dispatch({ type: udfValueMemberActions.ORG_MEMBER_UDF_VALUE_START, organisationId, memberAzureId });
    dispatch(
      replace(`${globalConstants.SCREEN_ORGANISATION}/${organisationId}/UserDefinedFields/Members/${memberAzureId}/udfValues`)
    );
  },

  fetchMemberUdfValue: (
    externalguid: string,
    memberAzureId: string
  ) => async (dispatch, getState) => {
    dispatch({
      type: actions.ORG_UDF_VALUE_FETCH_REQUEST
    });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_VALUE_FETCH)
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const udfValues: any = await httpServiceSecureGet(`/organisations/${externalguid}/Member/${memberAzureId}/udfValues`, gtToken);

      dispatch({
        type: actions.ORG_UDF_VALUE_FETCH_RESPONSE,
        udfValueList: udfValues.result ? udfValues.result : undefined,
      });
    } catch (error) {
      dispatch({
        type: actions.ORG_UDF_VALUE_FETCH_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_field_value_list', externalguid);
    }
  },

  requestAddUdfValue: (externalguid: string, memberAzureId: string, formData: any) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_VALUE_SAVE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_VALUE_SAVE));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePost(`/organisations/${externalguid}/Member/${memberAzureId}/udfValues`, gtToken, {
        fieldId: formData.fieldId,
        fieldValue: formData.fieldValue,
      });

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_VALUE_SAVE_RESPONSE,
        message: json?.message,
      });

    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_VALUE_SAVE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_field_value_add', externalguid);
    }
  },

  requestEditUdfValue: (externalguid: string, memberAzureId: string, fieldId: number, formData: any) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_VALUE_SAVE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_VALUE_SAVE));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePut(`/organisations/${externalguid}/Member/${memberAzureId}/udfValues/${fieldId}`, gtToken, {
        fieldValue: formData.fieldValue,
      });

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_VALUE_SAVE_RESPONSE,
        message: json?.message,
      });

    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_VALUE_SAVE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_field_value_edit', externalguid);
    }
  },

  requestDeleteUdfValue: (externalguid: string, memberAzureId: string, fieldId: number) => async (
    dispatch,
    getState
  ) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_UDF_VALUE_DELETE_REQUEST });
    dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_ORG_UDF_VALUE_DELETE));

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecureDelete(`/organisations/${externalguid}/Member/${memberAzureId}/udfValues/${fieldId}`, gtToken);

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_UDF_VALUE_DELETE_RESPONSE,
        message: json?.message,
      });
    } catch (error) {
      dispatch(notificationActionCreators.startShowNotification(error.result ? error.result:error.statusText));
      dispatch({
        type: actions.ORG_UDF_VALUE_DELETE_RESPONSE,
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('udf_field_value_delete', externalguid);
    }
  },

  requestRemoveFromOrg: (
    externalguid: string,
    members: any[],
  ) => async (dispatch, getState) => {
    if (getState().organisation.isLoading) {
      return;
    }

    dispatch({ type: actions.ORG_REMOVE_USER_SAVE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_ORG_REMOVE_USER_SAVE)
    );
    const memberAzureIds: string[] = members?.filter((f: any) => f.memberStatus === 'Inactive').map((value: any) => value.azureId);

    try {
      const url = `/organisations/${externalguid}/Members/RemoveFromOrg/`;

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePost(url, gtToken, {
        Members: memberAzureIds,
      });

      dispatch(notificationActionCreators.startShowNotification(json?.message || ''));
      dispatch({
        type: actions.ORG_REMOVE_USER_SAVE_RESPONSE,
        message: json?.message,
      });
    } catch (error) {
      updateUIWithErrorMessage(
        error,
        dispatch,
        notificationActionCreators.startShowNotification,
        actions.ORG_REMOVE_USER_SAVE_RESPONSE
      );
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
      saveDashboardInteractionEvent('remove_user_from_org', externalguid);
    }
  },
};



export default organisationActionCreators;
