/**
 * Reserve licence
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * React form
 */
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

/**
 * Fabric UI
 */
import {
  ActionButton,
  PrimaryButton,
  CommandBarButton,
  DefaultButton,
} from '@fluentui/react/lib/Button';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from '@fluentui/react/lib/DetailsList';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Selection } from '@fluentui/react/lib/Selection';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';

/**
 * React form field
 */
import TextField from '../../../components/ReduxFormFields/TextField';

/**
 * Components
 */
import {
  organisationActionCreators,
  IOrganisationState,
  IOrganisationProps,
  IOrganisationMemberSearchParams,
} from '../../../controllers/OrganisationController';
import { MembersActionResults } from './MembersActionResults';
import * as constants from '../../../common/Constants';
import { selectionActions } from './../OrganisationMembers.constants';
import { ListPager } from './../components/ListPager';

/**
 * Utilities
 */
import { sortItems } from '../../../common/Utils';
import { BRANDNAME } from '../../../common/Tooltips';


import ReserveLicenseAllocationCommonComponent from '@govteams/library/components/ManageOrganisation/ReserveLicenseAllocationComponent/';

type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps &
  InjectedFormProps;

const MembersLicencesReserveComponent = (props: IMergedProps) => {
  const {
    organisationActionCreators,
    membersReserved,
    membersActionResult,
    match,
    dispatch,
  } = props;

  const onGoBack = (event: any) => {
    organisationActionCreators.resetLicencesForm();
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}`)
    );
  };

  return (
    <ReserveLicenseAllocationCommonComponent
      brandName={BRANDNAME}
      membersReserved={membersReserved}
      membersActionResult={membersActionResult}
      urlMatch={match}
      onBackClick={onGoBack}

      reserveLicences={organisationActionCreators.reserveLicences}
      resetLicencesForm={organisationActionCreators.resetLicencesForm}
      requestFetch={organisationActionCreators.requestFetchReservedMembers}
      clearActionResult={organisationActionCreators.clearActionResult}
    />
  )
};


const mapStateToProps = (state) => ({
  membersReserved: state.organisation.membersReserved,
  isFormSubmitted: state.organisation.isFormSubmitted,
  membersActionResult: state.organisation.membersActionResult,
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch,
});

// export const MembersLicencesReserveForm = reduxForm({
//   form: 'MembersLicencesReserveForm',
// })(MembersLicencesReserveComponent);

export const MembersLicencesReserve = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersLicencesReserveComponent);

export default MembersLicencesReserve;
